import React from 'react';
import styles from './get-demo.module.scss'
import { Row, Col } from 'react-bootstrap'
import classNames from "classnames";
import { Link } from 'gatsby';

export default function GetDemo() {
  return (
    <div className={
      classNames(
        styles.paddingWrapper,
        'c-py-7',
        'position-relative',
        'rounded-8-px'
      )
    }>
      <div className={
        classNames(
          'z-index-1',
          'position-absolute',
          'overflow-hidden',
          'rounded-8-px',
          styles.fillTheWholeSpace,
          styles.background
        )
      }>
        <div className={
          classNames(
            styles.radialGradientGreen,
            'position-absolute'
          )
        }></div>
        <div className={
          classNames(
            styles.radialGradientViolet,
            'position-absolute'
          )
        }></div>
      </div>
      <Row>
        <Col lg={6} className={'z-index-2'}>
          <div className={styles.contentWrapper}>
            <h2 className={
              classNames(
                'line-height-1-2',
                'text-white',
                'font-size-24-34',
                styles.contentTitle
              )
            }>Explore Dashbot's platform</h2>
            <div className={
              classNames(
                styles.content,
                styles.entryContent,
                'font-size-17',
                'mt-3',
                'line-height-1-7'
              )
            }><span
              className="first-element-fix"></span>
              <p>Learn how 25,000+ bots are using Dashbot as their analytics platform.</p>
            </div>
          </div>
        </Col>
        <Col lg={6} className={'z-index-2 d-flex align-items-lg-center justify-content-lg-center'}>
          <div className={
            classNames(
              styles.contentWrapper,
              'pl-lg-0',
              'pt-5',
              'pt-lg-0'
            )
          }>
            <Link to={'/contact'} className={classNames(
              styles.crunchButton,
              styles.fullBackground,
              styles.fullBackgroundLarge,
              styles.fullBackgroundSecondaryColor,
              styles.hoverBackgroundWhite,
              'font-size-15',
              'mr-3',
            )}>Get a demo</Link>
            <a href={'https://reports.dashbot.io/signup'} className={classNames(
              styles.crunchButton,
              styles.fullBackground,
              styles.fullBackgroundLarge,
              styles.fullBackgroundWhiteColor,
              'font-size-15',
            )}>
              Create free account&nbsp;
              <svg className="crunch-button__arrow-icon d-block" width="8" height="12" viewBox="0 0 8 12" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M1.96533 10.5957L6.46533 6.0957L1.96533 1.5957" stroke="#262E4F" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </a>
          </div>

        </Col>
      </Row>
    </div>
  )
}
