import React from 'react';


export default [
  {
    "title": "Core Reports",
    "items": [
      {
        "name": "Users",
        "values": {
          "free": "__check",
          "build": "__check",
          "enterprise": "__check"
        }
      },
      {
        "name": "Activity (Sessions, Engagement, Retention, Message Volume)",
        "values": {
          "free": "__check",
          "build": "__check",
          "enterprise": "__check"
        }
      },
      {
        "name": "Transcripts",
        "values": {
          "free": "__check",
          "build": "__check",
          "enterprise": "__check"
        }
      },
      {
        "name": "Messages In/Out",
        "values": {
          "free": "__check",
          "build": "__check",
          "enterprise": "__check"
        }
      },
      {
        "name": "Intents In/Out",
        "values": {
          "free": "__check",
          "build": "__check",
          "enterprise": "__check"
        }
      },
      {
        "name": "Message Funnel",
        "values": {
          "free": "__check",
          "build": "__check",
          "enterprise": "__check"
        }
      }
    ]
  },
  {
    "title": (<>Surface and Extract Insights<p className={`font-weight-300`}>within your Conversational Data</p></>),
    "items": [
      {
        "name": "Transcript Search",
        "values": {
          "free": "__cross",
          "build": "__check",
          "enterprise": "__check"
        }
      },
      {
        "name": "Intent Entities",
        "values": {
          "free": "__cross",
          "build": "__check",
          "enterprise": "__check"
        }
      },
      {
        "name": "Message Search",
        "values": {
          "free": "__cross",
          "build": "__check",
          "enterprise": "__check"
        }
      },
      {
        "name": "Not Handled",
        "values": {
          "free": "__cross",
          "build": "__check",
          "enterprise": "__check"
        }
      },
      {
        "name": "CSV Exports",
        "values": {
          "free": "__cross",
          "build": "__check",
          "enterprise": "__check"
        }
      }
    ]
  },
  {
    "title": (<>Assess and Measure User Behavior<p className={`font-weight-300`}>to track conversions & drop-offs</p></>),
    "items": [
      {
        "name": "Conversation Paths",
        "values": {
          "free": "__cross",
          "build": "__check",
          "enterprise": "__check"
        }
      },
      {
        "name": "Funnels",
        "values": {
          "free": "__cross",
          "build": "__check",
          "enterprise": "__check"
        }
      },
      {
        "name": "Events (+ Event Parameters)",
        "values": {
          "free": "__cross",
          "build": "__check",
          "enterprise": "__check"
        }
      }
    ]
  },
  {
    "title": <>Monitor & React<p className={`font-weight-300`}>to Conversations in Real-Time</p></>,
    "items": [
      {
        "name": "Live Transcripts",
        "values": {
          "free": "__cross",
          "build": "__check",
          "enterprise": "__check"
        }
      },
      {
        "name": "Real-time Alerts",
        "values": {
          "free": "__cross",
          "build": "__check",
          "enterprise": "__check"
        }
      }
    ]
  },
  {
    "title": <>Machine Learning Tools & <br/>Specialized Reports<p className={`font-weight-300`}>to Filter Data and Uncover Key Insights at Scale</p></>,
    "items": [
      {
        "name": "Phrase Clusters",
        "values": {
          "free": "__cross",
          "build": "__cross",
          "enterprise": "__check"
        }
      },
      {
        "name": "Goals",
        "values": {
          "free": "__cross",
          "build": "__cross",
          "enterprise": "__check"
        }
      },
      {
        "name": "CSAT/NPS",
        "values": {
          "free": "__cross",
          "build": "__cross",
          "enterprise": "__check"
        }
      },
      {
        "name": "Audience Builder & Segmentation",
        "values": {
          "free": "__cross",
          "build": "__cross",
          "enterprise": "__check"
        }
      }
    ]
  },
  {
    "title": "Customizable Data Exports",
    "items": [
      {
        "name": "Custom Batch Export",
        "values": {
          "free": "__cross",
          "build": "__cross",
          "enterprise": "__check"
        }
      }
    ]
  },
  {
    "title": "Professional Services",
    "items": [
      {
        "name": "On-boarding",
        "values": {
          "free": "__cross",
          "build": "__cross",
          "enterprise": "__check"
        }
      },
      {
        "name": "Consulting Hours",
        "values": {
          "free": "__cross",
          "build": "__cross",
          "enterprise": "__check"
        }
      },
      {
        "name": "Roadmap Planning",
        "values": {
          "free": "__cross",
          "build": "__cross",
          "enterprise": "__check"
        }
      }
    ]
  }
]
