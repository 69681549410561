import React from "react"

const convertCheck = val =>
  val === "__check" ? <i className={`bx bx-check-circle`} /> : val
const convertAddOn = val =>
  val === "__addon" ? (
    <span className={`d-flex align-items-center`}>
      <i className={`bx bx-dollar`} />
      Add on
    </span>
  ) : (
    val
  )
const convertCross = val =>
  val === "__cross" ? <i className={`bx bx-x`} /> : val

export function convertToIcons(val) {
  return convertCross(convertCheck(convertAddOn(val)))
}
