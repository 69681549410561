import React from 'react';
import GetDemoComponent from '../../../components/demo/get-demo.js';
import ReactWOW from "react-wow";
import {Col, Container, Row} from "react-bootstrap";
import classNames from "classnames";
import styles from "./growth.module.scss";
import {Link} from "gatsby";

export default function Growth() {
  return (
    <Container>
      <ReactWOW delay=".1s" animation="fadeInDown">
        <div className={
          classNames(
            styles.paddingWrapper,
            'position-relative',
            'rounded-8-px'
          )
        }>
          <Row className={classNames(`justify-content-center`, styles.shadow)}>
            <Col lg={6} className={'z-index-2'}>
              <div className={styles.contentWrapper}>
                <h2 className={` ${styles.planTitle}`}>Growth Plan</h2>
                <div className={
                  classNames(
                    styles.content,
                    'font-size-17',
                    'mt-3',
                    'line-height-1-7',
                  )
                }><span
                  className="first-element-fix"></span>
                  <p>Are you a new startup or have custom requirements? </p>
                  <p>Tell us more about your project and we can help you put together a custom feature set based on your use-case</p>
                </div>
              </div>
            </Col>
            <Col lg={3} className={'z-index-2 d-flex align-items-lg-center justify-content-center'}>
              <div className={
                classNames(
                  styles.contentWrapper,
                  'pl-lg-0',
                  'pt-5',
                  'pt-lg-0'
                )
              }>
                <Link to={'/contact'} href={''} className={classNames(
                  styles.crunchButton,
                  styles.fullBackground,
                  styles.fullBackgroundLarge,
                  styles.fullBackgroundSecondaryColor,
                  'font-size-15',
                  'px-5'
                )}>
                  Contact Us
                </Link>
              </div>

            </Col>
          </Row>
        </div>
      </ReactWOW>
    </Container>
  )
}
