import React, { useEffect, useState } from "react"
import styles from "./header.module.scss"
import Header from "./header"
import { Row, Container, Col } from "react-bootstrap"

export default function StickyHeader({
  elId = "header",
  startEl = "header-begin",
  endEl = "header-end",
  className = "",
}) {
  const [start, setStart] = useState(0)
  const [end, setEnd] = useState(100)

  useEffect(() => {
    const startRect = document.getElementById(startEl).getBoundingClientRect()
    const endRect = document.getElementById(endEl).getBoundingClientRect()

    setStart(startRect.bottom)
    setEnd(endRect.bottom)
  }, [startEl, endEl])

  React.useEffect(() => {
    let elementId = document.getElementById(elId)
    document.addEventListener("scroll", () => {
      if (window.scrollY > start && window.scrollY + window.innerHeight < end) {
        elementId.classList.add(styles.isSticky)
      } else {
        elementId.classList.remove(styles.isSticky)
      }
    })
  }, [start, end, elId])

  return (
    <div id={elId} className={`${className} ${styles.stickyHeader} c-mt-8`}>
      <Container className={`${styles.compareSectionContainer}`}>
        <Row className={`align-items-end mx-lg-2 mt-0`}>
          <Header />
          <Col md={5} lg={6} className={`order-md-1 p-0`} />
        </Row>
      </Container>
    </div>
  )
}
