import React from "react"
import styles from "./hero.module.scss"
import { Col, Row, Container } from "react-bootstrap"
import Cards from "./cards"

function Hero() {
  return (
    <div className={`position-relative ${styles.section} pt-5`}>
      <div className={`${styles.heroBackground}`}></div>
      <div className={`${styles.heroGradient}`}></div>
      <div className={`${styles.triangle} ${styles.triangleBottom} h-50`}></div>
      <Container>
        <Row className={`justify-content-center`}>
          <Col md={10} xl={9} className={`${styles.pricingTitle}`}>
            <h1 className={`text-center`}>Plans that scale with you</h1>
            <Row className={`justify-content-center mt-3`}>
              <a
                href={`#whichPlan`}
                className={`text-center ${styles.whichPlan}`}
              >
                See details of each plan
              </a>
            </Row>
          </Col>
        </Row>
        <Cards />
      </Container>
    </div>
  )
}

export default Hero
