import React from 'react';
import Layout from '../layouts/pricing/pricing';
import Hero from '../page-components/pricing/hero/hero';
import Compare from '../page-components/pricing/compare/compare';
import Info from '../page-components/pricing/info/info';
import GetDemo from '../page-components/pricing/get-demo';

const Pricing = () => {
  return (
    <Layout>
      <Hero />
      <Compare />
      <Info />
      <GetDemo />
    </Layout>
  );
};

export default Pricing;
