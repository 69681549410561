import React from 'react';
import SEO from '../../components/seo';
import Footer from '../../components/footer/footer';
import styles from './pricing.module.scss';

const homepage = ({ children }) => {
  return (
    <React.Fragment>
      <SEO title={`Dashbot`} />
      <main className={styles.content}>{children}</main>
      <Footer />
    </React.Fragment>
  );
};

export default homepage;
