import React from "react"
import { Col, Row } from "react-bootstrap"
import styles from "./row.module.scss"
import { keys, xor } from "lodash"
import { convertToIcons } from "./util"

export default function RowNoExpand({ columns, data, curInd }) {
  const { name, tooltip, values } = data

  if (xor(keys(values), columns).length) {
    throw new Error(
      "something happened. please contact dashbot for support at contact@dashbot.io"
    )
  }

  const detailItems = columns.map((key, ind) => {
    const val = values[key]
    const lastInRow = ind === columns.length - 1

    return (
      <Col
        xs={4}
        className={`d-flex align-items-center justify-content-center ${
          lastInRow ? "" : styles.borderLine
        }`}
      >
        {convertToIcons(val)}
      </Col>
    )
  })

  return (
    <Row
      className={`${styles.tableRow} mx-lg-2 ${
        curInd % 2 === 0 ? "" : styles.bg
      }`}
    >
      <Col
        md={5}
        lg={6}
        className={`${styles.tableTitle} c-py-5 py-md-0 d-flex justify-content-center flex-column`}
      >
        <span className={`font-size-16-18 font-weight-medium ml-5 ml-md-6 text-dark-gray`}>
          {name}
          {tooltip ? (
            <span
              className={`d-none d-md-inline-block ml-1 ${styles.iconInfo}`}
            >
              <i
                className={`bx bx-info-circle`}
                alt={`Icon info`}
                data-original-title={`Collect data from your website without writing a single line of code`}
              />
            </span>
          ) : null}
        </span>
        {tooltip ? (
          <span
            className={`d-block d-md-none mt-2 ml-5 c-ml-md-6 font-size-13`}
          >
            {tooltip}
          </span>
        ) : null}
      </Col>
      <Col md={7} lg={6} xs={12} className={`text-center font-size-14-16`}>
        <Row>{detailItems}</Row>
      </Col>
    </Row>
  )
}
