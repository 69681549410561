import React from "react"
import { Col, Row } from "react-bootstrap"
import styles from "./header.module.scss"
import { Link } from 'gatsby'

export default function Header({ stickyElementId = "" }) {
  return (
    <Col
      id={stickyElementId}
      xs={12}
      md={7}
      lg={6}
      className={`order-md-2 text-center`}
    >
      <Row>
        <Col
          xs={4}
          className={`${styles.borderLine} pt-2 c-pb-lg-6 border-line ${styles.stickyPb_1}`}
        >
          <div
            className={`${styles.iconCircleWrapper} d-flex justify-content-center`}
          />
          <span
            className={`d-block  font-size-16-18 font-weight-medium mt-4 ${styles.stickyMt_0}`}
          >
            Free
          </span>
          <a
            href={`https://reports.dashbot.io/signup`}
            className={`${styles.buttonCrunchLarge} d-none d-sm-flex font-size-14 font-weight-medium line-height-1 mt-4 mt-md-3 mx-lg-1`}
          >
            Create a free account
          </a>
          <a
            href={`https://reports.dashbot.io/signup`}
            className={`${styles.buttonCrunchSmall} d-block d-sm-none font-size-14 font-weight-medium line-height-1 mt-4 mt-md-3  mx-lg-1`}
          >
            Create a free account
          </a>
        </Col>
        <Col
          xs={4}
          className={`pt-2 c-pb-lg-6 ${styles.borderLine} ${styles.stickyPb_1}`}
        >
          <div
            className={`${styles.iconCircleWrapper}  d-flex justify-content-center`}
          />
          <span
            className={`d-block  font-size-16-18 font-weight-medium mt-4 ${styles.stickyMt_0}`}
          >
            Build
          </span>
          <Link
            to={`/contact`}
            className={`${styles.buttonCrunchLarge} d-none d-sm-flex font-weight-medium font-size-14 line-height-1 mt-4 mt-md-3 mx-lg-1`}
          >
            Build your plan
          </Link>
          <Link
            to={`/contact`}
            className={`${styles.buttonCrunchSmall} d-block d-sm-none font-weight-medium font-size-14 line-height-1 mt-4 mt-md-3  mx-lg-1`}
          >
            Build your plan
          </Link>
        </Col>
        <Col xs={4} className={`pt-2 c-pb-lg-6 ${styles.stickyPb_1}`}>
          <div
            className={`${styles.iconCircleWrapper}  d-flex justify-content-center`}
          />
          <span
            className={`d-block  font-size-16-18 font-weight-medium mt-4 ${styles.stickyMt_0}`}
          >
            Enterprise
          </span>
          <Link
            to={`/contact`}
            className={`${styles.buttonCrunchLarge} d-none d-sm-flex font-size-14 font-weight-medium line-height-1 mt-4 mt-md-3 mx-lg-1`}
          >
            Get a demo
          </Link>
          <Link
            to={`/contact`}
            className={`${styles.buttonCrunchSmall} d-block d-sm-none font-size-14 font-weight-medium line-height-1 mt-4 mt-md-3 mx-lg-1`}
          >
            Get a demo
          </Link>
        </Col>
      </Row>
    </Col>
  )
}
