import React, { useState } from "react"
import { keys, xor } from "lodash"
import { Col, Row, Collapse } from "react-bootstrap"
import styles from "./row.module.scss"
import { convertToIcons } from "./util"

export default function RowExpand({ columns, data, curInd }) {
  const [open, setOpen] = useState(false)
  const { name, expand, values, tooltip } = data

  if (xor(keys(values), columns).length) {
    throw new Error(
      "something happened. please contact dashbot for support at contact@dashbot.io"
    )
  }

  const handleOpen = async e => {
    e.preventDefault()
    await setOpen(!open)
  }

  const detailItems = columns.map((key, ind) => {
    const val = values[key]
    const lastInRow = ind === columns.length - 1

    return (
      <Col
        xs={4}
        className={`d-flex align-items-center justify-content-center ${
          lastInRow ? "" : styles.borderLine
        }`}
      >
        {convertToIcons(val)}
      </Col>
    )
  })

  const children = expand.map((row, ind) => {
    const bg = (curInd + ind + 1) % 2 === 1
    const { name: childName, tooltip: childTooltip, values: childValues } = row

    const items = columns.map((key, itemInd) => {
      const val = childValues[key]
      const lastInRow = itemInd === columns.length - 1

      return (
        <Col
          xs={4}
          className={`${
            lastInRow ? "" : styles.borderLine
          } d-flex align-items-center justify-content-center`}
        >
          {convertToIcons(val)}
        </Col>
      )
    })

    return (
      <Row className={`${styles.tableRowChild} `}>
        <Col
          md={5}
          lg={6}
          className={`${styles.tableTitle} c-py-5 py-md-0 d-flex align-items-center justify-content-center justify-content-md-start flex-column flex-md-row mx-3 mx-md-0`}
        >
          <span className={`font-size-16-18 c-ml-md-7`}>
            {childName}
            {childTooltip ? (
              <span
                className={`d-none d-md-inline-block ml-1 ${styles.iconInfo}`}
              >
                <i
                  className={`bx bx-info-circle`}
                  alt={`Icon info`}
                  data-original-title={`Collect data from your website without writing a single line of code`}
                />
              </span>
            ) : null}
          </span>
          {childTooltip ? (
            <span
              className={`d-block d-md-none text-center mt-2 ml-5 c-ml-md-6 font-size-13`}
            >
              {childTooltip}
            </span>
          ) : null}
        </Col>
        <Col md={7} lg={6} xs={12} className={`text-center font-size-14-16`}>
          <Row>{items}</Row>
        </Col>
      </Row>
    )
  })

  return (
    <Row
      className={`${styles.tableRow} position-relative mx-lg-2 ${
        styles.singleTransition
      } ${curInd % 2 === 0 ? "" : styles.bg}`}
      aria-expanded={open}
      aria-controls={`pricingSources`}
      role={`button`}
    >
      <Col
        md={5}
        lg={6}
        className={`${styles.tableTitle} c-py-5 py-md-0 d-flex justify-content-center flex-column`}
      >
        <button className={`${styles.menuItemButton}`} onClick={handleOpen}>
          <i
            className={`${
              open ? styles.rotateDownTransition : styles.rotateUpTransition
            } bx bx-chevron-right`}
          />
        </button>
        <span
          className={`font-size-16-18 text-dark-blue-third font-weight-medium c-ml-5 c-ml-md-6`}
        >
          {name}
          {tooltip ? (
            <span
              className={`d-none d-md-inline-block ml-1 ${styles.iconInfo}`}
            >
              <i
                className={`bx bx-info-circle`}
                alt={`Icon info`}
                data-original-title={`Collect data from your website without writing a single line of code`}
              />
            </span>
          ) : null}
        </span>
        {tooltip ? (
          <span className={`d-block d-md-none mt-2 ml-5 ml-md-6 font-size-13`}>
            {tooltip}
          </span>
        ) : null}
      </Col>
      <Col md={7} lg={6} className={`text-center font-size-14-16`}>
        <Row className={`row`}>{detailItems}</Row>
      </Col>

      <Collapse in={open} className={``} id="pricingSources" role="tabpanel">
        {children}
      </Collapse>
    </Row>
  )
}
