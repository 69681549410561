import React from "react"
import { Row, Container, Col } from "react-bootstrap"
import styles from "./info.module.scss"

export default function Info() {
  return (
    <div className={`${styles.info}`}>
      <Container>
        <Row className={`${styles.row}`}>
          <Col xs={12}>
            <h3 className={`text-center font-size-18-30 font-weight-medium`}>
              Additional Info
            </h3>
          </Col>
        </Row>
        <Row className={`${styles.row}`}>
          <Col xs={12} sm={6}>
            <h5 className={`${styles.bodyTitle}`}>
              Are you an Agency or Chatbot Platform?
            </h5>
            <p className={`${styles.body}`}>
              Dashbot has additional partnership options for agencies and
              chatbot platforms, including co-branding and multi-client access.
            </p>
            <p className={`${styles.body}`}>
              Please contact us to learn more about our agency offerings.
            </p>
          </Col>
          <Col xs={12} sm={6}>
            <h5 className={`${styles.bodyTitle}`}>
              Looking to Build a Chatbot?
            </h5>
            <p className={`${styles.body}`}>
              Are you looking to build a chatbot and have historical user
              transcripts from live agent chats or IVR?
            </p>
            <p className={`${styles.body}`}>
              Dashbot can help kick start development by identifying common
              themes and Intents through our Phrase Clustering, which can be
              used to train your NLP engine. Learn more here.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
