import React from 'react';
import GetDemoComponent from '../../components/demo/get-demo.js';
import ReactWOW from "react-wow";
import {Container} from "react-bootstrap";

export default function GetDemo() {
  return (<Container>
    <ReactWOW delay=".1s" animation="fadeInDown">
      <GetDemoComponent/>
    </ReactWOW>
  </Container>)
}
