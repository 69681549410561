import React from "react"
import RowExpand from "./row-expand"
import RowNoExpand from "./row-no-expand"

export default function Rows({ data, columns, curInd }) {
  if (!data || !columns) return null

  const items = data.map((row, ind) => {
    if (row.expand && Object.keys(row.expand)) {
      return (
        <RowExpand
          key={row.name}
          data={row}
          columns={columns}
          curInd={curInd + ind}
        />
      )
    }
    return (
      <RowNoExpand
        key={row.name}
        data={row}
        columns={columns}
        curInd={curInd + ind}
      />
    )
  })

  return <>{items}</>
}
