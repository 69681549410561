import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import styles from "./compare.module.scss"
import Header from "./table/header"
import Rows from "./table/rows"
import StickyHeader from "./table/header-sticky"
import compareData from "./compare-data.js"

export default function Compare() {
  let globalInd = 0
  const stickyElementId = "header-begin"
  const noStickElementId = "header-end"

  const table = compareData.map((section, ind) => {
    const { title, items } = section
    const curInd = globalInd
    globalInd += items.length

    const head =
      ind === 0 ? (
        <Row className={`align-items-end mx-lg-2 c-mt-7`}>
          <Header stickyElementId={stickyElementId} />
          <Col md={5} lg={6} className={`order-md-1 p-0`}>
            <h3
              className={`${styles.sectionTitle} font-size-18-30  c-mt-8 mt-md-0 c-mb-6 mb-md-4 pt-4`}
            >
              {title}
            </h3>
          </Col>
        </Row>
      ) : (
        <Row className={`align-items-end mx-lg-2`}>
          <Col md={5} lg={6} className={`px-0`}>
            <h3
              class={`${styles.sectionTitle} font-size-18-30 c-mt-7 mt-md-0 c-mb-6 mb-md-4 pt-4`}
            >
              {title}
            </h3>
          </Col>
          <Col md={7} lg={6}>
            <Row>
              <Col
                xs={4}
                className={`${styles.borderLine} ${styles.borderLineTitle}`}
              />
              <Col
                xs={4}
                className={`${styles.borderLine} ${styles.borderLineTitle}`}
              />
            </Row>
          </Col>
        </Row>
      )

    return (
      <React.Fragment key={title}>
        {head}
        <Rows
          data={items}
          columns={["free", "build", "enterprise"]}
          curInd={curInd}
        />
      </React.Fragment>
    )
  })

  return (
    <div className={`${styles.compareSection} c-mt-8`}>
      <Container
        className={`${styles.compareSectionContainer}`}
        id={noStickElementId}
      >
        <h2 className={`text-center ${styles.title} font-weight-400`}>Compare Plans</h2>
        {table}
        <StickyHeader />
      </Container>
    </div>
  )
}
