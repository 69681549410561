import React from "react"
import { Card, Row, Col } from "react-bootstrap"
import styles from "./cards.module.scss"
import { Link } from 'gatsby'
import Growth from './growth'

export default function Cards() {
  return (
    <>
    <Row className={`${styles.plans}`}>
      <Col xs={12} md={4} className={`h-auto ${styles.planTable}`}>
        <div
          className={`${styles.planTable__inner} h-100 d-flex flex-column bg-white text-center align-items-center`}
        >
          <h2 className={` ${styles.planTitle} `}>Free</h2>
          <a
            href={`https://reports.dashbot.io/signup`}
            type="button"
            className={`btn btn-outline-primary mt-4 ${styles.planBtn}`}
          >
            Create a free account
          </a>
          <div
            className={`${styles.planTable__info} d-flex align-items-center mt-3`}
          >
            <p className={styles.plan_oneLiner}>
              For individual developers experimenting with chatbots and voice skills.
            </p>
          </div>
          <div className={`${styles.planTable__features} w-100`}>
            <h3
              className={`${styles.planTable__featuresHeader} ${styles.borderTop} pt-5 mt-3 w-100`}
            >
              What you can do
            </h3>
            <Card.Text
              className={`${styles.planTable__listCheck} mt-4 text-left`}
            >
              <ul className={`list-unstyled font-weight-300`}>
                <li>
                  <p>
                    <i className="flaticon-tick"></i>
                    {" Access to Core Reports"}
                  </p>
                </li>
                <li>
                  <p>
                    <i className="flaticon-tick"></i>
                    {" Get integrated within an hour"}
                  </p>
                </li>
                <li>
                  <p>
                    <i className="flaticon-tick"></i>
                    {" Capture and retain historical data"}
                  </p>
                </li>
                <li>
                  <p>
                    <i className="flaticon-tick"></i>
                    {" 3 chatbots/skills"}
                  </p>
                </li>
                <li>
                  <p>
                    <i className="flaticon-tick"></i>
                    {" Limited monthly message volume"}
                  </p>
                </li>
              </ul>
            </Card.Text>
          </div>
        </div>
      </Col>
      <Col
        xs={12}
        md={4}
        className={`${styles.planTable} h-auto mt-md-0 c-mt-6`}
      >
        <div
          className={`${styles.planTable__inner} bg-white d-flex flex-column text-center align-items-center`}
        >
          <h2 className={`mt-4 ${styles.planTitle}`}>Build</h2>
          <Link
            to={`/contact`}
            type="button"
            className={`btn btn-outline-primary mt-4 ${styles.planBtn}`}
          >
            Build your plan
          </Link>
          <div
            className={`${styles.planTable__info} font-weight-300 d-flex align-items-center mt-3`}
          >
            <p className={styles.plan_oneLiner}>
              For companies looking for a comprehensive analytics solution and  optimization tool.
            </p>
          </div>
          <div className={`${styles.planTable__features} w-100`}>
            <h3
              className={`${styles.planTable__featuresHeader} ${styles.borderTop} pt-5 mt-3 w-100`}
            >
              All of Free Plus
            </h3>
            <Card.Text
              className={`${styles.planTable__listCheck} mt-4 text-left`}
            >
              <ul className={`list-unstyled font-weight-300`}>
                <li>
                  <p><i className="flaticon-tick"></i>
                  {" Basic reports"}</p>
                </li>
                <li>
                  <p><i className="flaticon-tick"></i>
                 {" Track user behavior"}</p>
                </li>
                <li>
                  <p><i className="flaticon-tick"></i>
                  {" Monitor conversations in real-time"}</p>
                </li>
                <li>
                  <p><i className="flaticon-tick"></i>
                    {" 1M monthly message volume"}</p>
                </li>
                <li>
                  <p><i className="flaticon-tick"></i>
                    {" Access to Data History*"}</p>
                </li>
              </ul>
            </Card.Text>
          </div>
        </div>
      </Col>
      <Col
        xs={12}
        md={4}
        className={`h-auto ${styles.planTable} c-mt-6 mt-md-0`}
      >
        <div
          className={`${styles.planTable__inner} h-100 bg-white d-flex flex-column text-center align-items-center`}
        >
          <h2 className={` ${styles.planTitle}`}>Enterprise</h2>
          <Link
            to={`/contact`}
            type="button"
            className={`btn btn-outline-primary mt-4 ${styles.planBtn}`}
          >
            Get a demo
          </Link>
          <div
            className={`${styles.planTable__info} d-flex align-items-center mt-3`}
          >
            <p className={styles.plan_oneLiner}>
              For companies looking to access the full power of Dashbot’s optimization tools plus much more.
            </p>
          </div>
          <div className={`${styles.planTable__features} w-100`}>
            <h3
              className={`${styles.planTable__featuresHeader}  ${styles.borderTop} pt-5 mt-3 w-100`}
            >
              All of Build Plus
            </h3>
            <Card.Text
              className={`${styles.planTable__listCheck} mt-4 text-left`}
            >
              <ul className={`list-unstyled font-weight-300`}>
                <li>
                  <p>
                  <i className="flaticon-tick"></i>
                  {" Machine learning tools & specialized reports"}
                  </p>
                </li>
                <li>
                  <p>
                    <i className="flaticon-tick"></i>
                    {" Customizable reports & data exports"}
                  </p>
                </li>
                <li>
                  <p>
                    <i className="flaticon-tick"></i>
                    {" Professional services & dedicated customer success"}
                  </p>
                </li>
                <li>
                  <p>
                    <i className="flaticon-tick"></i>
                    {" Enterprise SLA"}
                  </p>
                </li>
              </ul>
            </Card.Text>
          </div>
        </div>
      </Col>
    </Row>
      <Row>
        <Growth/>
      </Row>
    </>
  )
}
